import { Box, Grid, Stack, Card, CardContent, Typography } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { ProcedureSummaryTile } from "./ProcedureSummaryTile";
import { PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import { Loader } from "shared/components/Loaders/Loader";
import { DropDownSelect } from "shared/components/DropDownSelect";
import { useX_getProductServiceAggregations } from "api_extension/useQueryX/cacheProductServiceData/useX_getProductServiceAggregations";
import { ProductServiceCacheProductServiceMonthlyAggregate } from "api";
import { ProcedureSelect } from "sections/diagnosis/components/ProcedureSelect";
import NoDataAvailable from "shared/components/NoDataAvailable";
import { useMemo_totalMembersInFilter } from "shared/filters/useMemo_TotalMembersInFilter";

function ProcedureSummaryTileList() {
	const { values: primaryFilterValues } = useContext(PrimaryFiltersContext);

	useContext(PrimaryFiltersContext);
	let [selected, setSelected] = useState<string | null>(null);
	const [activeSort, setActiveSort] = useState<string>("Total Spend");
	const sortOptions = [
		{ value: "Total Spend", label: "Total Spend" },
		{ value: "Members", label: "Members" },
		{ value: "Total Procedures", label: "Total Procedures" },
	];
	const [activeOrder, setActiveOrder] = useState<"DESC" | "ASC">("DESC");
	const orderOptions = [
		{ value: "DESC", label: "Descending" },
		{ value: "ASC", label: "Ascending" },
	];

	const codeAggregationResponse = useX_getProductServiceAggregations(
		[selected ?? ""],
		{
			memberTypes: primaryFilterValues.Members,
		},
		false,
		{ orderBy: activeOrder, sortBy: activeSort }
	);
	const totalMembers = useMemo_totalMembersInFilter();
	const aggregatedCodes = useMemo(() => {
		if (codeAggregationResponse.data?.response?.aggregateItems) {
			return codeAggregationResponse.data?.response?.aggregateItems;
		}
		return null;
	}, [codeAggregationResponse]);

	const codeAggMap = useMemo(() => {
		const m = new Map<string, ProductServiceCacheProductServiceMonthlyAggregate>();
		aggregatedCodes?.forEach((x: any) => {
			if (x.productServiceCode) {
				m.set(x.productServiceCode, x);
			}
		});
		return m;
	}, [aggregatedCodes]);

	const codesToDisplay = useMemo(() => {
		let codes = aggregatedCodes?.map((x) => x.productServiceCode);

		return codes as string[] | null;
	}, [aggregatedCodes]);

	const codesOrNoData =
		codesToDisplay && codesToDisplay.length > 0 ? (
			<Grid item xs={12}>
				{codesToDisplay.map((codeId, x) => {
					let aggItem = codeAggMap.get(codeId);
					return <ProcedureSummaryTile key={codeId} monthlyAgg={aggItem} totalMembers={totalMembers} />;
				})}
			</Grid>
		) : (
			<Card sx={{ display: "flex", width: "100%", justifyContent: "center", margin: "1rem" }}>
				<CardContent>
					<NoDataAvailable />
				</CardContent>
			</Card>
		);

	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardContent>
							<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
								<ProcedureSelect styles={{ width: 30 }} setValue={setSelected} />
								<Stack sx={{ flexDirection: "row", alignItems: "center" }}>
									<Stack className={"date-range-input"} sx={{ flexDirection: "row", pr: 3 }}>
										<Stack
											className={"date-range-container"}
											direction="row"
											spacing="1"
											sx={{
												height: "2.5rem",
												borderRadius: "2.5rem",
												border: "2px solid #fff",
												backgroundColor: "gray.main",
												px: 1.5,
												color: "gray.dark",
												boxShadow: 4,
												alignItems: "center",
												"& .MuiButtonBase-root": {
													height: "100%",
													fontSize: "1rem",

													"& svg": {
														color: "primary.main",
														fontSize: "1.25rem",
													},
												},
											}}
										>
											<Typography component={"span"} sx={{ color: "primary.main", pr: 1, fontWeight: 700 }}>
												Sort By:
											</Typography>
											<Stack data-name="SortSelectStart" sx={{ flexDirection: "row" }}>
												<DropDownSelect
													title={activeSort}
													type="radio"
													options={sortOptions}
													selectedValues={[activeSort]}
													setValues={(v: Array<string>) => {
														setActiveSort(v[0]);
													}}
												/>
											</Stack>
											<Typography component={"span"} sx={{ color: "primary.main", pr: 1, fontWeight: 700 }}>
												Order By:
											</Typography>
											<Stack data-name="OrderSelectStart" sx={{ flexDirection: "row" }}>
												<DropDownSelect
													title={activeOrder === "ASC" ? "Ascending" : "Descending"}
													type="radio"
													options={orderOptions}
													selectedValues={[activeOrder]}
													setValues={(v: Array<string>) => {
														setActiveOrder(v[0] as "DESC" | "ASC");
													}}
												/>
											</Stack>
										</Stack>
									</Stack>
								</Stack>
							</Stack>
						</CardContent>
					</Card>
				</Grid>

				{codesToDisplay ? codesOrNoData : <Loader />}
			</Grid>
		</Box>
	);
}
export { ProcedureSummaryTileList };
