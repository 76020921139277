import { Box, Button, Typography } from "@mui/material";

import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import { DataGridPro, GridColumns, GridSortDirection, GridSortItem } from "@mui/x-data-grid-pro";

import useDataGrid from "shared/components/grid/useDataGrid";
import displayConverter from "../../../../shared/utilities/displayConverter";
import ValueTile from "../../../../shared/components/ValueTile";

import { Swiper, SwiperSlide } from "swiper/react";
import { MedicationTypeApiRequest } from "../MedicationDetailsMain";
import { useX_GetDoctorByMedication } from "api_extension/useQueryX/cacheDoctorData/useX_GetDoctorByMedication";
import { QueryStatusDisplay } from "shared/components/Loaders/QueryStatusDisplay";
import { useEffect, useMemo, useState } from "react";

import { useQueryClient } from "react-query";

import { ModelsMedicationRangeCacheMonthlyAggregate } from "api";
import { useNavigate } from "react-router-dom";
import SimpleTooltipColumn from "shared/components/grid/GridColumn/SimpleTooltipColumn";

type MedicationDetailsDoctorsProps = {
	apiRequest: MedicationTypeApiRequest;
	rangeAggregateItem?: ModelsMedicationRangeCacheMonthlyAggregate;
};

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
	return <DataGridToolbar header={"Doctors List"} {...props} enableExport={false} />;
}

const defaultSortField = "filtered_prescription_cost";
function MedicationDetailsDoctors(props: MedicationDetailsDoctorsProps) {
	const qc = useQueryClient();
	const { apiRequest, rangeAggregateItem } = props;
	const navigate = useNavigate();
	const columnsX: GridColumns = [
		{
			field: "name",
			headerName: "Doctor Name",
			flex: 1,
			minWidth: 260,
			headerAlign: "left",
			align: "left",
			sortable: false,
			renderCell: (params) => {
				const item = params.row.doctor;
				const followItemType = item?.entityTypeCode === 1 ? "PROVIDER" : "ORGANIZATION";
				const fullName = item.firstName ? item?.firstName + " " + item?.lastName : item.organizationName;
				const displayName = fullName.length > 25 ? fullName.substring(0, 25) + "..." : fullName;
				return (
					<SimpleTooltipColumn
						code={item.npi}
						rating={item.radiusRating}
						followItemType={followItemType}
						tooltipTitle={
							<Box>
								<Typography>{fullName}</Typography>
								<Typography>NPI: {item.npi}</Typography>
							</Box>
						}
						name={displayName}
					/>
				);
			},
		},
		{
			field: "code",
			headerName: "Specialty",
			flex: 2,
			minWidth: 50,
			headerAlign: "left",
			align: "left",
			sortable: false,
			renderCell: (params) => {
				return (
					<Typography display="block" style={{ overflowWrap: "break-word", wordWrap: "break-word", whiteSpace: "normal" }}>
						{params.row.doctor?.specialtyText}
					</Typography>
				);
			},
		},
		{
			field: "filtered_prescription_count",
			headerName: "Fill Count",
			flex: 0.5,
			minWidth: 100,
			headerAlign: "left",
			align: "left",
			valueGetter: (params) => params.row.medicationCodeCountAggregation_Combined?.count,
		},
		{
			field: "filtered_prescription_cost",
			headerName: "total spend",
			flex: 0.5,
			minWidth: 100,
			headerAlign: "left",
			align: "left",
			valueGetter(params) {
				return displayConverter.toUSD(params.row.medicationCodeCountAggregation_Combined?.cost);
			},
		},
		{
			field: "Actions",
			headerName: "",
			headerAlign: "right",
			align: "right",
			width: 128,
			sortable: false,
			disableColumnMenu: true,

			renderCell: ({ row }) => (
				<Button size="small" onClick={() => navigate("/itemDetails?itemType=PROVIDER&code=" + row.code)} color="secondary">
					Details
				</Button>
			),
		},
	];
	const { grid } = useDataGrid("doctors", columnsX);

	const pageSize = 10;
	const [page, set_page] = useState(0);
	const [skip, set_skip] = useState(0);
	const take = 10;
	useEffect(() => {
		set_skip(page * pageSize);
	}, [page, pageSize]);

	const [sm, set_sm] = useState<GridSortItem>({ field: defaultSortField, sort: "desc" });
	const [field, desc] = useMemo(() => {
		let f = defaultSortField;
		let d = true;
		if (sm.field) {
			switch (sm.field) {
				case "filtered_medication_cost":
					f = "filtered_medication_cost";
					break;
				case "filtered_medication_avgcost":
					f = "filtered_medication_avgcost";
					break;
				case "filtered_medication_count":
					f = "filtered_medication_count";
					break;
			}
			d = sm.sort !== "asc"; // if its not asc then set as desc
		}
		return [f, d];
	}, [sm]);

	const apiResult = useX_GetDoctorByMedication(apiRequest, { skip: skip, take: take, field: field, descending: desc });

	const avgCost = useMemo(() => {
		if (rangeAggregateItem?.totalAmount && rangeAggregateItem.occurrenceCount) {
			return rangeAggregateItem?.totalAmount / rangeAggregateItem.occurrenceCount;
		} else {
			return 0;
		}
	}, [rangeAggregateItem?.totalAmount, rangeAggregateItem?.occurrenceCount]);
	return (
		<Box>
			<button
				style={{ display: "none" }}
				onClick={() => {
					qc.invalidateQueries("getDoctorByMedication");
				}}
			>
				Invalidate
			</button>
			<QueryStatusDisplay queryResult={apiResult} loaderSize="huge">
				<>
					<Box
						sx={{
							"& .swiper-container": {
								pb: { xs: 4, md: 0 },
							},
							"& .swiper-pagination-bullets": {
								bottom: "0",
							},
						}}
					>
						<Swiper
							spaceBetween={16}
							slidesPerView={1}
							pagination={{
								clickable: true,
							}}
							breakpoints={{
								"700": {
									slidesPerView: 3,
								},
								"1200": {
									slidesPerView: 4,
								},
							}}
						>
							<SwiperSlide>
								<ValueTile
									value={displayConverter.insertThousandsCommas(apiResult?.data?.response?.doctorCount ?? -1)}
									title="Doctors"
									minHeight="7rem"
								/>
							</SwiperSlide>
							<SwiperSlide>
								<ValueTile
									value={displayConverter.insertThousandsCommas(rangeAggregateItem?.occurrenceCount)}
									title="Fill Count"
									minHeight="7rem"
								/>
							</SwiperSlide>
							<SwiperSlide>
								<ValueTile value={displayConverter.toUSD(Number(rangeAggregateItem?.totalAmount))} title="Total Spend" minHeight="7rem" />
							</SwiperSlide>
							<SwiperSlide>
								<ValueTile value={displayConverter.toUSD(Number(avgCost))} title="Average Spend" minHeight="7rem" />
							</SwiperSlide>
						</Swiper>
					</Box>

					<Box sx={{ display: "flex", height: "100%" }}>
						<Box
							sx={{
								flexGrow: 1,
							}}
						>
							{apiResult.data?.response?.aggs ? (
								<DataGridPro
									{...grid}
									columns={columnsX}
									autoHeight
									rowCount={apiResult?.data?.response?.doctorCount ?? -1}
									getRowId={(row) => row.code}
									rows={apiResult.data.response.aggs}
									components={{ Toolbar: Toolbar }}
									page={page}
									pagination
									onPageChange={(newPage) => {
										set_page(newPage);
									}}
									pageSize={pageSize}
									rowsPerPageOptions={[10]}
									sx={{ pt: 2 }}
									disableColumnFilter
									disableColumnMenu
									sortingMode="server"
									sortModel={[sm]}
									onColumnHeaderClick={(x, y) => {
										let sort: GridSortDirection = "desc";
										if (x.field === sm.field && sm.sort === "desc") {
											sort = "asc";
										}
										set_sm({ field: x.field, sort: sort });
									}}
								/>
							) : null}
						</Box>
					</Box>
				</>
			</QueryStatusDisplay>
		</Box>
	);
}

export { MedicationDetailsDoctors };
