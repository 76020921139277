import React, { useState } from "react";
import { Box, Grid, Card, CardContent, CardHeader, Tabs } from "@mui/material";
import { TabsUtilities } from "shared/components/Tabs/TabsUtilities";
import { FollowedCacheMonthlyType } from "./tabs/FollowedCacheMonthlyType";

const { TabPanel, TabControl } = TabsUtilities.create_TabRenderFunctions("followed-items-types");

function FollowedItemsMain() {
	const tabs = [
		"Diagnosis",
		"Prescriptions",
		"Procedures",
		"Doctors",
		//"Organizations",
		"Members",
	];

	const [activeTab, set_activeTab] = useState<number>(0);

	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={12}>
					<Card elevation={4}>
						<CardHeader
							sx={{
								"& .MuiCardHeader-action": {
									width: "100%",
								},
							}}
							action={
								<Tabs
									value={activeTab}
									onChange={(_event: React.SyntheticEvent, newValue: number) => {
										set_activeTab(newValue);
									}}
									aria-label="Followed Item Type Tabs"
									variant="scrollable"
									scrollButtons={false}
									sx={{
										width: "100%",
										"& .MuiTab-root": {
											flex: { xs: "none", md: "1" },
											whiteSpace: "nowrap",
										},
									}}
								>
									{tabs.map((label, value) => {
										return <TabControl key={label} label={label} value={value} />;
									})}
								</Tabs>
							}
						></CardHeader>
						<CardContent
							sx={{
								pt: 0,
							}}
						>
							<TabPanel value={activeTab} index={0}>
								<FollowedCacheMonthlyType itemType="DIAGNOSIS" titleText="Followed Diagnoses" />
								<hr />
								<FollowedCacheMonthlyType itemType="DIAGNOSIS_RANGE" titleText="Followed Diagnosis Ranges" />
							</TabPanel>
							<TabPanel value={activeTab} index={1}>
								<FollowedCacheMonthlyType itemType="MEDICATION" titleText="Followed Prescriptions" />
							</TabPanel>
							<TabPanel value={activeTab} index={2}>
								<FollowedCacheMonthlyType itemType="PRODUCTSERVICE" titleText="Followed Procedures" />
							</TabPanel>
							<TabPanel value={activeTab} index={3}>
								<FollowedCacheMonthlyType itemType="PROVIDER" titleText="Followed Doctors" />
							</TabPanel>
							{/* <TabPanel value={activeTab} index={4}>
								<FollowedCacheMonthlyType itemType="ORGANIZATION" titleText="Followed Organizations" />
							</TabPanel> */}
							<TabPanel value={activeTab} index={4}>
								<FollowedCacheMonthlyType itemType="PATIENT" titleText="Followed Members" />
							</TabPanel>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}
export { FollowedItemsMain };
