import { Box, Input } from "@mui/material";
import { CacheTreatmentDataAddNdcDrugstructuresResult, addNdcDrugStructures } from "api";
import { useState } from "react";
import { ObjectMerger } from "shared/utilities/ObjectMerger";

const outerScopeTracker: {
	skip: number;
	set_skip: (x: number) => any;
	take: number;
	set_take: (x: number) => any;
	results: Array<AddNdcsResultCounts>;
	totalResultsFromCallChain: AddNdcsResultCounts | null;
	breakNextCallChain: boolean;
	isCalling: boolean;
} = {
	skip: -1,
	set_skip: () => null,
	take: -1,
	set_take: () => null,
	results: [],
	breakNextCallChain: false,
	isCalling: false,
	totalResultsFromCallChain: null,
};
type AddNdcsResultCounts = {
	requestedCount?: number;
	existingCount?: number;
	addedCount?: number;
	notFoundCount?: number;
	totalDistinctNdcCountInCacheData?: number;
};

function convertAddNdcResponseToCounts(result: CacheTreatmentDataAddNdcDrugstructuresResult): AddNdcsResultCounts {
	return {
		requestedCount: result.requestedCodes?.length,
		existingCount: result.existingCodes?.length,
		addedCount: result.codesImported?.length,
		notFoundCount: result.existingCodes?.length,
		totalDistinctNdcCountInCacheData: result.totalDistinctNdcCountInCacheData,
	};
}

const MedicationCacheTools = () => {
	const [skip, set_skip] = useState<number>(0);
	const [take, set_take] = useState<number>(50);

	outerScopeTracker.skip = skip;
	outerScopeTracker.set_skip = (x: number) => {
		outerScopeTracker.skip = x;
		set_skip(x);
	};
	outerScopeTracker.take = take;
	outerScopeTracker.set_take = (x: number) => {
		outerScopeTracker.take = x;
		set_take(x);
	};

	const [codes, set_codes] = useState<string>("");
	const [codesArray, set_codesArray] = useState<Array<string> | null>(null);

	const set_codesX = (x: string) => {
		let arr = null;
		set_codes(x);
		if (x && codes.trim() !== "") {
			arr = x
				.split(",")
				.map((c) => c.trim())
				.filter((c) => c && c !== "");
		}
		set_codesArray(arr);
	};

	/*
	
	
	
	
	
	*/
	const [importByCodesResult, set_importByCodesResult] = useState<CIM>(null);
	/*
	
	
	
	
	*/

	const call_addNdcDrugStructures = async () => {
		outerScopeTracker.isCalling = true;
		let x = await addNdcDrugStructures({ codes: codesArray, page: { skip: outerScopeTracker.skip, take: take } });
		if (x.data) {
			outerScopeTracker.results.push(convertAddNdcResponseToCounts(x.data));
			if (!outerScopeTracker.totalResultsFromCallChain) {
				outerScopeTracker.totalResultsFromCallChain = {
					requestedCount: 0,
					existingCount: 0,
					addedCount: 0,
					notFoundCount: 0,
					totalDistinctNdcCountInCacheData: 0,
				};
			}
			outerScopeTracker.totalResultsFromCallChain = ObjectMerger.CombineObjectNumericProperties(
				outerScopeTracker.totalResultsFromCallChain,
				convertAddNdcResponseToCounts(x.data),
				false,
				["totalDistinctNdcCountInCacheData"]
			);
		}
		outerScopeTracker.set_skip(outerScopeTracker.skip + take);

		if (outerScopeTracker.breakNextCallChain) {
			alert("Call Chain Broken");
			outerScopeTracker.breakNextCallChain = false;
			outerScopeTracker.isCalling = false;
			return;
		}

		if (x.success) {
			setTimeout(call_addNdcDrugStructures, 500);
		} else {
			alert(x.errorMessage ?? "Latest call in chain failed - no errorMessage");
			outerScopeTracker.isCalling = false;
		}
	};

	return (
		<div>
			<h1>Medication Cache Tools</h1>
			<button
				onClick={async () => {
					call_addNdcDrugStructures();
				}}
			>
				Add NDC Codes Call Chain
			</button>
			<button
				onClick={() => {
					outerScopeTracker.breakNextCallChain = true;
				}}
			>
				Break Call Chain
			</button>
			<div>
				<label htmlFor="skipInput">Skip</label>
				<Input
					id="skipInput"
					type="number"
					style={{ border: "solid 1px green", borderRadius: "4px" }}
					value={skip}
					onChange={(e) => {
						console.log(e);
						let val = parseInt(e.target.value as CIM);
						if (typeof val === "number") {
							outerScopeTracker.set_skip(val);
						}
					}}
				/>
			</div>
			<div>
				<label htmlFor="takeInput">Take</label>
				<Input
					id="takeInput"
					type="number"
					style={{ border: "solid 1px green", borderRadius: "4px" }}
					value={take}
					onChange={(e) => {
						console.log(e);
						let val = parseInt(e.target.value as CIM);
						if (typeof val === "number") {
							outerScopeTracker.set_take(val);
						}
					}}
				/>
			</div>
			<br />
			<br />
			<div style={{ display: "flex", flexDirection: "row" }}>
				<div style={{ minWidth: "300px" }}>
					<button
						onClick={async () => {
							console.log(codesArray);
							console.log(codes);
							let x = await addNdcDrugStructures({ codes: codesArray });
							set_importByCodesResult(x.data);
						}}
					>
						Add Codes
					</button>
					<div>
						<label htmlFor="codesInput">Codes (comma delimited)</label>
						<Box>
							<Input
								id="codesInput"
								style={{ border: "solid 1px green", borderRadius: "4px" }}
								type="string"
								value={codes}
								onChange={(event) => {
									set_codesX(event.target.value);
								}}
							/>
						</Box>
					</div>
				</div>
				<div>
					<pre>{JSON.stringify(importByCodesResult, null, 4)}</pre>
				</div>
			</div>
			<div style={{ border: "solid 1px blue" }}>
				<pre>{JSON.stringify(outerScopeTracker.totalResultsFromCallChain, null, 2)}</pre>
			</div>

			<div>
				{outerScopeTracker.results.map((d, i) => {
					return (
						<div key={"key_" + i}>
							<pre>{JSON.stringify(d, null, 2)}</pre>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export { MedicationCacheTools };
