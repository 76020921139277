import React, { useContext, useMemo } from "react";
import { Box, Grid, Card, CardContent, CardHeader, Tabs, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { TabsUtilities } from "shared/components/Tabs/TabsUtilities";
import { PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import DiagnosisNotificationList from "notifications/DiagnosisNotificationList";
import { DateConverter } from "shared/utilities/DateConverter";
import { useMemo_totalMembersInFilter } from "shared/filters/useMemo_TotalMembersInFilter";
import { ProductServiceDetailsOverview } from "./panels/ProductServiceDetailsOverview";
import { useX_getProductServiceAggregations } from "api_extension/useQueryX/cacheProductServiceData/useX_getProductServiceAggregations";
import { ProductServiceDetailsMembers } from "./panels/ProductServiceDetailsMembers";
import { ProductServiceDetailsDoctors } from "./panels/ProductServiceDetailsDoctors";
import { ProductServiceDetailsConditions } from "./panels/ProductServiceDetailsConditions";
import { ProductServiceDetailsImpact } from "./panels/ProductServiceDetailsImpact";
import { ProductServiceDetailsMedications } from "./panels/ProductServiceDetailsMedications";
import { UserFollowedItemTitle } from "shared/components/UserFollowedItemTitle";

interface ProductServiceTypeApiRequest extends DateRangeStrings {
	searchQuery?: string;
	codes: Array<string>;
	jobIds: Array<string>;
}

interface ProductServiceRangeApiRequest extends DateRangeStrings {}

const { TabPanel, TabControl } = TabsUtilities.create_TabRenderFunctions("procedure-details");

function ProductServiceDetailsMain() {
	const tabs = ["Overview", "Members", "Doctors", "Prescriptions", "Conditions", "Radius Impact"];
	const { values: primaryFilterValues } = useContext(PrimaryFiltersContext);

	useContext(PrimaryFiltersContext);
	const totalMembers = useMemo_totalMembersInFilter();
	let params = useParams() as { procedureId: string };
	const procedureID: string = params.hasOwnProperty("procedureId") ? params["procedureId"] : "NA";
	const procedureTypeRequest: ProductServiceTypeApiRequest = useMemo(() => {
		return {
			startDate: DateConverter.stringDateForAPI_Nullable(primaryFilterValues.DateRangeCustomDates.start),
			endDate: DateConverter.stringDateForAPI_Nullable(primaryFilterValues.DateRangeCustomDates.end),
			jobIds: primaryFilterValues.Members,
			codes: [procedureID],
		};
	}, [primaryFilterValues.DateRangeCustomDates.start, primaryFilterValues.DateRangeCustomDates.end, primaryFilterValues.Members, procedureID]);
	const rangeAggregationResponse = useX_getProductServiceAggregations([procedureID], procedureTypeRequest);

	const rangeAggregateItem = rangeAggregationResponse?.data?.response?.aggregateItems?.[0];

	const [activeTab, setActiveTab] = React.useState<number>(0);

	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardContent>
							{rangeAggregateItem && (
								<UserFollowedItemTitle
									tooltip={<Typography>{rangeAggregateItem.productServiceCode}</Typography>}
									code={rangeAggregateItem.productServiceCode}
									name={rangeAggregateItem.name}
									followedItemType={"PRODUCTSERVICE"}
								/>
							)}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} lg={9}>
					<Card elevation={4}>
						<CardHeader
							sx={{
								"& .MuiCardHeader-action": {
									width: "100%",
								},
							}}
							action={
								<Tabs
									value={activeTab}
									onChange={(event: React.SyntheticEvent, newValue: number) => {
										setActiveTab(newValue);
									}}
									aria-label="ProductService Details Tabs"
									variant="scrollable"
									scrollButtons={false}
									sx={{
										width: "100%",
										"& .MuiTab-root": {
											flex: { xs: "none", md: "1" },
											whiteSpace: "nowrap",
										},
									}}
								>
									{tabs.map((label, value) => {
										return <TabControl key={label} label={label} value={value} />;
									})}
								</Tabs>
							}
						></CardHeader>
						<CardContent
							sx={{
								pt: 0,
							}}
						>
							<TabPanel value={activeTab} index={0}>
								<ProductServiceDetailsOverview
									rangeAggregateItem={rangeAggregateItem}
									apiRequest={procedureTypeRequest}
									totalMembers={totalMembers}
								/>
							</TabPanel>
							<TabPanel value={activeTab} index={1}>
								<ProductServiceDetailsMembers
									apiRequest={procedureTypeRequest}
									rangeAggregateItem={rangeAggregateItem}
									totalMembers={totalMembers}
								/>
							</TabPanel>
							<TabPanel value={activeTab} index={2}>
								<ProductServiceDetailsDoctors apiRequest={procedureTypeRequest} rangeAggregateItem={rangeAggregateItem} />
							</TabPanel>
							<TabPanel value={activeTab} index={3}>
								<ProductServiceDetailsMedications apiRequest={procedureTypeRequest} rangeAggregateItem={rangeAggregateItem} />
							</TabPanel>
							<TabPanel value={activeTab} index={4}>
								<ProductServiceDetailsConditions apiRequest={procedureTypeRequest} rangeAggregateItem={rangeAggregateItem} />
							</TabPanel>
							<TabPanel value={activeTab} index={5}>
								<ProductServiceDetailsImpact apiRequest={procedureTypeRequest} rangeAggregateItem={rangeAggregateItem} />
							</TabPanel>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} lg={3}>
					<Card elevation={4}>
						<CardHeader title="Member Notification"></CardHeader>
						<CardContent>
							<DiagnosisNotificationList diagnosisID={procedureID} />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}
export { ProductServiceDetailsMain };
export type { ProductServiceTypeApiRequest, ProductServiceRangeApiRequest };
