import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { queryDoctorBySearchTerm, QueryDoctorBySearchTermParams } from "api";
import { useQuery } from "react-query";

export const useX_getDoctorsBySearchTerm = (req: QueryDoctorBySearchTermParams) => {
	let fqrX = useFilterQuery();

	let request: QueryDoctorBySearchTermParams = {
		jobIds: fqrX.memberTypes.toString(),
		searchQuery: req.searchQuery,
		size: req.size,
	};

	let cacheKeys = ["queryDoctorBySearchTerm", "queryDoctorBySearchTerm_" + JSON.stringify(request)];
	return useQuery(cacheKeys, queryDoctorBySearchTerm.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};
