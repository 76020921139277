import { Box, Grid, Stack, Card, CardContent, Typography } from "@mui/material";
import { icons } from "../../../theme";
import Icon from "../../../shared/Icon";
import { ConditionSelect } from "../components/ConditionSelect";
import { useDiagnosisFilters } from "DataProvider/contexts/Context_DiagnosisFilters_Provider";
import { Condition } from "../../../shared/schemas/dataStructures";
import { ModelsDiagnosisRangeCacheMonthlyAggregate } from "api";
import { Loader } from "shared/components/Loaders/Loader";
import { useEffectOnce } from "react-use";
import { useMemo, useState } from "react";
import { DiagnosisSummaryTile } from "./DiagnosisSummaryTile";
import { useX_getDiagnosisRangeAggregations } from "api_extension/useQueryX/cacheDiagnosisData/useX_getDiagnosisRangeAggregations";
import { useContextCodeDefinitions } from "DataProvider/contexts/Context_CodeDefinitions_Provider";
import { DropDownSelect } from "shared/components/DropDownSelect";
import { useMemo_totalMembersInFilter } from "shared/filters/useMemo_TotalMembersInFilter";

function DiagnosisSummaryTileList() {
	const [contextDiagnosisCodes, diagnosisFilters] = [useContextCodeDefinitions(), useDiagnosisFilters()];
	const [activeSort, setActiveSort] = useState<string>("Total Spend");
	const sortOptions = [
		{ value: "Total Spend", label: "Total Spend" },
		{ value: "Members", label: "Members" },
		{ value: "Total Diagnoses", label: "Total Diagnoses" },
	];
	const [activeOrder, setActiveOrder] = useState<"DESC" | "ASC">("DESC");
	const orderOptions = [
		{ value: "DESC", label: "Descending" },
		{ value: "ASC", label: "Ascending" },
	];
	const conditionRanges = useMemo(() => {
		return contextDiagnosisCodes.Conditions?.data;
	}, [contextDiagnosisCodes.Conditions?.data]);

	useEffectOnce(() => {
		if (diagnosisFilters.values.Conditions.length > 0) {
			diagnosisFilters.setValues({ Conditions: [] });
		}
	});

	const totalMembers = useMemo_totalMembersInFilter();

	const conditionRangesIds = useMemo(() => {
		if (!conditionRanges) {
			return [];
		}
		return conditionRanges.map((c) => c.id);
	}, [conditionRanges]);

	const conditionRangeMap = useMemo(() => {
		const m = new Map<string, Condition>();
		if (conditionRanges) {
			conditionRanges.forEach((x) => {
				if (x.id) {
					m.set(x.id, x);
				}
			});
		}
		return m;
	}, [conditionRanges]);
	const rangeAggregationResponse = useX_getDiagnosisRangeAggregations(null, conditionRangesIds, {}, false, { orderBy: activeOrder, sortBy: activeSort });

	const rangeAggregateItems = useMemo(() => {
		if (rangeAggregationResponse.data?.response?.aggregateItems) {
			return rangeAggregationResponse.data?.response?.aggregateItems;
		}
		return null;
	}, [rangeAggregationResponse]);

	const rangeAggMap = useMemo(() => {
		const m = new Map<string, ModelsDiagnosisRangeCacheMonthlyAggregate>();
		rangeAggregateItems?.forEach((x) => {
			if (x.range) {
				m.set(x.range, x);
			}
		});
		return m;
	}, [rangeAggregateItems]);

	let currentConditionFilter = useMemo(() => {
		return diagnosisFilters.values.Conditions.map((x) => x.id);
	}, [diagnosisFilters.values.Conditions]);

	const rangesToDisplay = useMemo(() => {
		let ranges = rangeAggregateItems
			?.map((x) => x.range)
			.filter((x) => {
				return x && conditionRangesIds.indexOf(x) !== -1;
			});

		if (currentConditionFilter && currentConditionFilter.length > 0) {
			ranges = ranges?.filter((x) => {
				return currentConditionFilter.indexOf(x as string) !== -1;
			});
		}
		return ranges as string[] | null;
	}, [currentConditionFilter, conditionRangesIds, rangeAggregateItems]);

	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardContent>
							<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
								<Box
									sx={{
										width: { xs: "100%", md: "auto" },
										pb: { xs: 1, md: 0 },
									}}
								>
									<ConditionSelect
										icon={<Icon icon={icons.diagnosis} sx={{ color: "primary.main", pl: 1 }}></Icon>}
										placeholder={"Filter by Condition"}
									/>
								</Box>
								<Stack sx={{ flexDirection: "row", alignItems: "center" }}>
									<Stack className={"date-range-input"} sx={{ flexDirection: "row", pr: 3 }}>
										<Stack
											className={"date-range-container"}
											direction="row"
											spacing="1"
											sx={{
												height: "2.5rem",
												borderRadius: "2.5rem",
												border: "2px solid #fff",
												backgroundColor: "gray.main",
												px: 1.5,
												color: "gray.dark",
												boxShadow: 4,
												alignItems: "center",
												"& .MuiButtonBase-root": {
													height: "100%",
													fontSize: "1rem",

													"& svg": {
														color: "primary.main",
														fontSize: "1.25rem",
													},
												},
											}}
										>
											<Typography component={"span"} sx={{ color: "primary.main", pr: 1, fontWeight: 700 }}>
												Sort By:
											</Typography>
											<Stack data-name="SortSelectStart" sx={{ flexDirection: "row" }}>
												<DropDownSelect
													title={activeSort}
													type="radio"
													options={sortOptions}
													selectedValues={[activeSort]}
													setValues={(v: Array<string>) => {
														setActiveSort(v[0]);
													}}
												/>
											</Stack>
											<Typography component={"span"} sx={{ color: "primary.main", pr: 1, fontWeight: 700 }}>
												Order By:
											</Typography>
											<Stack data-name="OrderSelectStart" sx={{ flexDirection: "row" }}>
												<DropDownSelect
													title={activeOrder === "ASC" ? "Ascending" : "Descending"}
													type="radio"
													options={orderOptions}
													selectedValues={[activeOrder]}
													setValues={(v: Array<string>) => {
														setActiveOrder(v[0] as "DESC" | "ASC");
													}}
												/>
											</Stack>
										</Stack>
									</Stack>
								</Stack>
							</Stack>
						</CardContent>
					</Card>
				</Grid>

				{rangesToDisplay ? (
					<Grid item xs={12}>
						{rangesToDisplay.map((rangeId, x) => {
							let cond = conditionRangeMap.get(rangeId);
							let aggItem = rangeAggMap.get(rangeId);
							return <DiagnosisSummaryTile key={`DST-${cond}`} condition={cond} rcma={aggItem} totalMembers={totalMembers} />;
						})}
					</Grid>
				) : (
					<Loader />
				)}
			</Grid>
		</Box>
	);
}
export { DiagnosisSummaryTileList };
