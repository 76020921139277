import { Box, Grid, Stack, Card, CardContent, Typography } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { MedicationSummaryTile } from "./MedicationSummaryTile";
import { PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import { useX_getMedicationAggregations } from "api_extension/useQueryX/cacheMedicationData/useX_getMedicationAggregations";
import { Loader } from "shared/components/Loaders/Loader";
import { DropDownSelect } from "shared/components/DropDownSelect";
import { MedicationSelect } from "sections/diagnosis/components/MedicationSelect";
import NoDataAvailable from "shared/components/NoDataAvailable";
import { ResendQueryButton } from "shared/components/Developer/ResendQueryButton";
import { useMemo_totalMembersInFilter } from "shared/filters/useMemo_TotalMembersInFilter";

function MedicationSummaryTileList() {
	const { values: primaryFilterValues } = useContext(PrimaryFiltersContext);

	useContext(PrimaryFiltersContext);
	const totalMembers = useMemo_totalMembersInFilter();

	const [activeSort, set_activeSort] = useState<string>("Total Spend");
	const sortOptions = [
		{ value: "Total Spend", label: "Total Spend" },
		{ value: "Members", label: "Members" },
		{ value: "Prescriptions", label: "Prescriptions" },
	];
	const [activeOrder, set_activeOrder] = useState<"DESC" | "ASC">("DESC");
	const orderOptions = [
		{ value: "DESC", label: "Descending" },
		{ value: "ASC", label: "Ascending" },
	];
	let [selected, setSelected] = useState<string | null>(null);

	const codesAggregatedResponse = useX_getMedicationAggregations(
		[selected ?? ""],
		{
			memberTypes: primaryFilterValues.Members,
		},
		false,
		{ orderBy: activeOrder, sortBy: activeSort }
	);

	const codeAggregatedItems = useMemo(() => {
		if (codesAggregatedResponse.data?.response?.aggregateItems) {
			return codesAggregatedResponse.data?.response?.aggregateItems;
		}
		return null;
	}, [codesAggregatedResponse]);

	const listDisplay = useMemo(() => {
		let display;
		if (codeAggregatedItems && codeAggregatedItems.length > 0) {
			display = (
				<Grid item xs={12}>
					{codeAggregatedItems?.map((aggItem) => {
						return <MedicationSummaryTile key={aggItem.code} rcma={aggItem} totalMembers={totalMembers}></MedicationSummaryTile>;
					})}
				</Grid>
			);
		} else {
			display = (
				<Card sx={{ display: "flex", width: "100%", justifyContent: "center", margin: "1rem" }}>
					<CardContent>
						<NoDataAvailable />
					</CardContent>
				</Card>
			);
		}
		return display;
	}, [codeAggregatedItems, totalMembers]);

	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardContent>
							<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
								<MedicationSelect styles={{ width: 30 }} setValue={setSelected} />
								<Stack sx={{ flexDirection: "row", alignItems: "center" }}>
									<Stack className={"date-range-input"} sx={{ flexDirection: "row", pr: 3 }}>
										<Stack
											className={"date-range-container"}
											direction="row"
											spacing="1"
											sx={{
												height: "2.5rem",
												borderRadius: "2.5rem",
												border: "2px solid #fff",
												backgroundColor: "gray.main",
												px: 1.5,
												color: "gray.dark",
												boxShadow: 4,
												alignItems: "center",
												"& .MuiButtonBase-root": {
													height: "100%",
													fontSize: "1rem",

													"& svg": {
														color: "primary.main",
														fontSize: "1.25rem",
													},
												},
											}}
										>
											<Typography component={"span"} sx={{ color: "primary.main", pr: 1, fontWeight: 700 }}>
												Sort By:
											</Typography>
											<Stack data-name="SortSelectStart" sx={{ flexDirection: "row" }}>
												<DropDownSelect
													title={activeSort}
													type="radio"
													options={sortOptions}
													selectedValues={[activeSort]}
													ariaLabel="RiskModel"
													setValues={(v: Array<string>) => {
														set_activeSort(v[0]);
													}}
												/>
											</Stack>
											<Typography component={"span"} sx={{ color: "primary.main", pr: 1, fontWeight: 700 }}>
												Order By:
											</Typography>
											<Stack data-name="OrderSelectStart" sx={{ flexDirection: "row" }}>
												<DropDownSelect
													title={activeOrder === "ASC" ? "Ascending" : "Descending"}
													type="radio"
													options={orderOptions}
													selectedValues={[activeOrder]}
													ariaLabel="RiskModel"
													setValues={(v: Array<string>) => {
														set_activeOrder(v[0] as "DESC" | "ASC");
													}}
												/>
											</Stack>
										</Stack>
									</Stack>
								</Stack>
							</Stack>
							<ResendQueryButton queryCacheName="getMedicationAggregations" />
						</CardContent>
					</Card>
				</Grid>

				{codesAggregatedResponse.isLoading || codesAggregatedResponse.isFetching ? <Loader /> : listDisplay}
			</Grid>
		</Box>
	);
}
export { MedicationSummaryTileList };
