import { Box, Rating, Tooltip, Typography } from "@mui/material";
import { ModelsLookUpDictionaries, ModelsLookUpItem, ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts } from "api";
import { UserFollowedItemButton } from "shared/components/UserFollowedItemButton";

const GetLookupItemForCode = (code: string, itemType: CacheMonthlyTypes, lookupDictionaries: ModelsLookUpDictionaries): ModelsLookUpItem => {
	let item: ModelsLookUpItem = {
		itemType: itemType,
		code: code,
		// provider?: ModelsDoctorStub;
		// medication?: ModelsTerminalDrugStructureData;
		// diagnosis?: ModelsICD10CMLight;
		// diagnosisRange?: ModelsICD10Range;
		// hcpcs?: ModelsHCPCSBase;
		// icdpcs?: ModelsICDPCS;
		// patientRiskCategory?: ModelsPatientRiskCategory;
	};
	switch (itemType) {
		case "PATIENT":
			item.patientRiskCategory = lookupDictionaries.patientRiskCategory?.itemDictionary?.[code];
			break;
		case "DIAGNOSIS":
			item.diagnosis = lookupDictionaries.diagnosis?.itemDictionary?.[code];
			break;
		case "DIAGNOSIS_RANGE":
			item.diagnosisRange = lookupDictionaries.diagnosisRange?.itemDictionary?.[code];
			break;
		case "PRODUCTSERVICE":
			item.hcpcs = lookupDictionaries.hcpcs?.itemDictionary?.[code];
			item.icdpcs = lookupDictionaries.icdpcs?.itemDictionary?.[code];
			break;
		case "MEDICATION":
			item.medication = lookupDictionaries.medication?.itemDictionary?.[code];
			break;
		case "PROVIDER":
			item.provider = lookupDictionaries.provider?.itemDictionary?.[code];
			break;
		case "ORGANIZATION":
			item.organization = lookupDictionaries.organization?.itemDictionary?.[code] ?? lookupDictionaries.facility?.itemDictionary?.[code];
			break;
	}
	return item;
};

/*


















*/

const RowDisplayForEntityType = ({
	item,
	noTooltip,
}: {
	item: { code?: string; lookUpItem?: ModelsLookUpItem };
	noTooltip?: boolean;
	//LEAVE ---  ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts
}) => {
	if (!item) {
		return <p>[missing]</p>;
	}

	if (!item.lookUpItem) {
		return <p>[{item.code}]</p>;
	}

	let render;
	switch (item.lookUpItem.itemType) {
		case "PATIENT":
			render = RenderRowDisplay.PATIENT(item);
			break;
		case "DIAGNOSIS":
			render = RenderRowDisplay.DIAGNOSIS(item);
			break;
		case "DIAGNOSIS_RANGE":
			render = RenderRowDisplay.DIAGNOSIS_RANGE(item);
			break;
		case "PRODUCTSERVICE":
			render = RenderRowDisplay.PRODUCTSERVICE(item);
			break;
		case "MEDICATION":
			render = RenderRowDisplay.MEDICATION(item);
			break;
		case "PROVIDER":
			render = RenderRowDisplay.PROVIDER(item, noTooltip ?? false);
			break;
		case "ORGANIZATION":
		case "FACILITY":
			render = RenderRowDisplay.ORGANIZATION(item);
			break;
		default:
			render = (
				<p>
					{item.code}
					{item.lookUpItem?.itemType}
				</p>
			);
			break;
	}
	return render;
};

const RenderRowDisplay = {
	PATIENT: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts) => {
		return item.lookUpItem?.patientRiskCategory ? (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				<Box>
					{item.lookUpItem.patientRiskCategory ? (
						<UserFollowedItemButton
							itemType="PATIENT"
							code={`${item.lookUpItem.patientRiskCategory.patientId}||${item.lookUpItem.patientRiskCategory.jobId}`}
						/>
					) : null}
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Typography display="block" style={{ overflowWrap: "break-word", wordWrap: "break-word", whiteSpace: "normal" }}>
						{item.lookUpItem.patientRiskCategory.patientId}
					</Typography>
				</Box>
			</Box>
		) : (
			<p>{item.code} : Not Found</p>
		);
	},
	DIAGNOSIS: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts) => {
		let x = item?.lookUpItem?.diagnosis;
		return x ? (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "center",
					width: "100%",
				}}
			>
				<Box>{x.icd10Code ? <UserFollowedItemButton itemType="DIAGNOSIS" code={x.icd10Code} /> : null}</Box>
				<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
					<Typography display="block" style={{ overflowWrap: "break-word", wordWrap: "break-word", whiteSpace: "normal" }}>
						{x.title}
					</Typography>
				</Box>
			</Box>
		) : (
			<p>{item.code} : Not Found</p>
		);
	},
	DIAGNOSIS_RANGE: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts) => {
		let x = item?.lookUpItem?.diagnosisRange;
		return x ? (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "center",
					width: "100%",
				}}
			>
				<Box>{x.range ? <UserFollowedItemButton itemType="DIAGNOSIS_RANGE" code={x.range} /> : null}</Box>
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<Typography display="block" style={{ overflowWrap: "break-word", wordWrap: "break-word", whiteSpace: "normal" }}>
							{x.description}
						</Typography>
					</Box>
				</Box>
			</Box>
		) : (
			<p>{item.code} : Not Found</p>
		);
	},
	PRODUCTSERVICE: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts) => {
		let x = item?.lookUpItem?.hcpcs;
		let y = item?.lookUpItem?.icdpcs;
		return x || y ? (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "center",
					width: "100%",
				}}
			>
				{x ? (
					<>
						<Box>{x.code ? <UserFollowedItemButton itemType="PRODUCTSERVICE" code={x.code} /> : null}</Box>
						<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
							<Typography display="block" style={{ overflowWrap: "break-word", wordWrap: "break-word", whiteSpace: "normal" }}>
								{x.description}
							</Typography>
						</Box>
					</>
				) : (
					<>
						<Box>{y!.code ? <UserFollowedItemButton itemType="PRODUCTSERVICE" code={y!.code} /> : null}</Box>
						<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
							<Typography display="block" style={{ overflowWrap: "break-word", wordWrap: "break-word", whiteSpace: "normal" }}>
								{y!.description}
							</Typography>
						</Box>
					</>
				)}
			</Box>
		) : (
			<p>{item.code} : Not Found</p>
		);
	},
	MEDICATION: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts) => {
		let x = item?.lookUpItem?.medication;
		return x ? (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "center",
					width: "100%",
				}}
			>
				<Box>{x.ndc ? <UserFollowedItemButton itemType="MEDICATION" code={x.ndc} /> : null}</Box>
				<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
					<Typography display="block" style={{ overflowWrap: "break-word", wordWrap: "break-word", whiteSpace: "normal" }}>
						{x.name ?? x.ndc}
					</Typography>
				</Box>
			</Box>
		) : (
			<p>{item.code} : Not Found</p>
		);
	},
	PROVIDER: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts, noTooltip: boolean) => {
		let doc = item?.lookUpItem?.provider;
		const name = doc?.firstName ? `${doc?.firstName} ${doc?.lastName}` : doc?.organizationName;
		const doctorName = (
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
					<Typography display="block" style={{ overflowWrap: "break-word", wordWrap: "break-word", whiteSpace: "normal" }}>
						{name}
					</Typography>
				</Box>
				{doc?.radiusRating && (
					<Box sx={{ display: "flex", flexDirection: "row" }}>
						<Rating size="small" name="read-only" value={doc?.radiusRating} readOnly />
					</Box>
				)}
			</Box>
		);
		return doc ? (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "center",
					width: "100%",
				}}
			>
				<Box>{doc.npi ? <UserFollowedItemButton itemType="PROVIDER" code={doc.npi} /> : null}</Box>
				{noTooltip ? (
					doctorName
				) : (
					<Tooltip arrow placement="right" title={<Box>NPI: {doc.npi}</Box>}>
						{doctorName}
					</Tooltip>
				)}
			</Box>
		) : (
			<p>{item.code} : Not Found</p>
		);
	},
	ORGANIZATION: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts) => {
		let doc = item?.lookUpItem?.organization;
		return doc ? (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "center",
					width: "100%",
				}}
			>
				<Box>{doc.npi ? <UserFollowedItemButton itemType="ORGANIZATION" code={doc.npi} /> : null}</Box>
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<Typography display="block" style={{ overflowWrap: "break-word", wordWrap: "break-word", whiteSpace: "normal" }}>
							{doc.organizationName}
						</Typography>
					</Box>
					{doc.radiusRating && (
						<Box sx={{ display: "flex", flexDirection: "row" }}>
							<Rating size="small" name="read-only" value={doc.radiusRating} readOnly />
						</Box>
					)}
				</Box>
			</Box>
		) : (
			<p>{item.code} : Not Found</p>
		);
	},
};

export { RowDisplayForEntityType, GetLookupItemForCode };
