import React, { useContext } from "react";
import logo from "../../radius-logo-white.png";
import { Box, Stack, ButtonBase } from "@mui/material";
import NavMenuItem from "./NavMenuItem";
import PageHeader from "./PageHeader";
import { useState } from "react";
import { icons } from "../../theme";
import Icon from "../Icon";
import { AccountManagerContext } from "DataProvider/contexts/AccountManager";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { Loader } from "shared/components/Loaders/Loader";
import { useContextAppSettings } from "DataProvider/contexts/Context_AppSettings";
import { HealthIcon, health_icons_svg } from "shared/components/Icons/HealthIcon";

type Props = React.PropsWithChildren<{}>;

function Shell({ children }: Props) {
	const [state, setState] = useState("");

	const toggleMenu = () => {
		setState(state === "" ? "open" : "");
	};

	const user = useContext(AccountManagerContext);
	const settings = useContextAppSettings();

	const pf = usePrimaryFilters();

	return (
		<Box
			className={`${state}`}
			sx={{
				minHeight: "100vh",
				"& .main-menu": {
					transition: "width .5s ease, left .25s ease",
					width: {
						xs: "14rem",
						sm: "4rem",
					},
					zIndex: 5,
				},
				"&.open .main-menu": {
					width: "14rem",
					left: {
						xs: "0",
						sm: "0",
					},
				},
				"& .nav-menu-items .MuiTypography-root": {
					display: "block",
					visibility: "hidden",
					opacity: "0",
					transition: "all .125s ease",
					transitionDelay: ".125s",
					transitionProperty: "visibility, opacity",
					whiteSpace: "nowrap",
					width: "100%",
				},
				"&.open .nav-menu-items .MuiTypography-root": {
					visibility: "visible",
					opacity: "1",
				},
				"& .nav-menu-items .icon-container": {
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100%",
					minWidth: "3rem",
					fontSize: "1.25rem",
				},
				"& .main-content": {
					pl: {
						xs: 3,
						sm: 12,
					},
					pr: {
						xs: 1,
						sm: 4,
					},
					flex: "1",
					transition: "padding-left .5s ease",
				},
				"&.open .main-content": {
					pl: {
						xs: 3,
						sm: 33,
					},
				},
				"& .MuiTouchRipple-root": {
					zIndex: "1",
				},
			}}
		>
			<Box
				className="main-menu"
				sx={{
					position: "fixed",
					top: 0,
					left: {
						xs: "-14rem",
						sm: 0,
					},
					backgroundColor: "primary.main",
					height: "100vh",
				}}
			>
				<Stack
					sx={{
						position: "relative",
						height: "4.5rem",
						pl: 1.5,
						"& img": { width: "160px" },
					}}
					justifyContent="center"
				>
					<Box className="radius-care-logo" sx={{ overflow: "hidden" }}>
						<img src={logo} alt="logo" />
					</Box>

					<Stack
						sx={{
							position: "absolute",
							top: "0",
							right: "-1.5rem",
							height: "100%",
							justifyContent: "center",
						}}
					>
						<ButtonBase
							sx={{
								position: "relative",
								backgroundColor: "primary.main",
								width: "1.5rem",
								height: "2.5rem",
								borderRadius: "0 .5rem .5rem 0",
								"&::before": {
									content: `''`,
									position: "absolute",
									top: "-1rem",
									left: "0",
									width: "1rem",
									height: "1rem",
									borderRadius: "50%",
									backgroundColor: "Transparent",
									boxShadow: "-.5rem .5rem 0 .125rem #02024C",
								},
								"&::after": {
									content: `''`,
									position: "absolute",
									bottom: "-1rem",
									left: "0",
									width: "1rem",
									height: "1rem",
									borderRadius: "50%",
									backgroundColor: "Transparent",
									boxShadow: "-.5rem -.5rem 0 .125rem #02024C",
								},
							}}
							onClick={toggleMenu}
						>
							<Icon
								className="menu-icon"
								sx={{
									color: "#fff",
									transition: "transform .25s ease",
								}}
								fixedWidth
								icon={icons.close}
							/>
						</ButtonBase>
					</Stack>
				</Stack>

				<Stack className="nav-menu-items" sx={{ flex: "1" }}>
					<NavMenuItem label="Overview" icon={<HealthIcon iconSVG={health_icons_svg.home_alt} />} to="/overview"></NavMenuItem>
					<NavMenuItem
						label="Diagnosis"
						icon={
							<Icon
								className="icon"
								sx={{
									transition: "color .25s ease",
								}}
								icon={icons.diagnosis}
							/>
						}
						to="/diagnosis"
						additionalMatches={["/diagnosis/details/*"]}
					></NavMenuItem>
					<NavMenuItem label="Procedures" icon={<Icon className="icon" icon={icons.procedure} />} to="/procedures/*"></NavMenuItem>
					<NavMenuItem label="Prescriptions" icon={<HealthIcon size={24} iconSVG={health_icons_svg.rx} />} to="/prescriptions/*"></NavMenuItem>

					<NavMenuItem
						label="Spend"
						icon={
							<Icon
								className="icon"
								sx={{
									transition: "color .25s ease",
								}}
								icon={icons.revenue}
							/>
						}
						to="/spend/overview"
					></NavMenuItem>
					<NavMenuItem
						label="Members"
						icon={
							<Icon
								className="icon"
								sx={{
									transition: "color .25s ease",
								}}
								icon={icons.users}
							/>
						}
						to="/members/*"
					></NavMenuItem>
					<NavMenuItem label="Doctors" icon={<HealthIcon size={24} iconSVG={health_icons_svg.doctor} />} to="/doctors/*"></NavMenuItem>
					<NavMenuItem
						label="Reporting"
						icon={
							<Icon
								className="icon"
								sx={{
									transition: "color .25s ease",
								}}
								icon={icons.reporting}
							/>
						}
						to="/reporting/*"
					></NavMenuItem>
					{settings.disable_following_items ? null : (
						<NavMenuItem
							label="Followed Items"
							icon={
								<Icon
									className="icon"
									sx={{
										transition: "color .25s ease",
									}}
									icon={icons.eye}
								/>
							}
							to="/followedItems/*"
						></NavMenuItem>
					)}
					{user.accountManager.userDetails?.user?.userRole === "Administrator" ? (
						<NavMenuItem
							label="Settings"
							icon={
								<Icon
									className="icon"
									sx={{
										transition: "color .25s ease",
									}}
									icon={icons.settings}
								/>
							}
							to="/settings/*"
						></NavMenuItem>
					) : null}
				</Stack>
			</Box>

			<Box className="main-content">
				<PageHeader></PageHeader>
				<Box sx={{ py: 1 }}>{pf.values.MembersInitialized ? children : <Loader />}</Box>
			</Box>
		</Box>
	);
}

export { Shell };
