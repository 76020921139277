import { ReactNode, useState } from "react";
import { Autocomplete, SxProps, TextField, Theme } from "@mui/material";
import { ModelsHCPCSBase } from "api";
import { useX_getProductServiceDropdown } from "api_extension/useQueryX/cacheProductServiceData/useX_getProductServiceDropdown";

type ProcedureSelectProps = {
	icon?: ReactNode;
	placeholder?: string;
	styles?: SxProps<Theme>;
	setValue?: (values: string | null) => void;
};
function ProcedureSelect(props: Readonly<ProcedureSelectProps>) {
	let [query, setQuery] = useState<string>("");
	let dropdownOptions = useX_getProductServiceDropdown({ searchQuery: query }).data?.procedures;
	return (
		<Autocomplete
			sx={{
				minWidth: "30rem",
				width: "100%",
				...props.styles,
			}}
			autoComplete
			getOptionLabel={(code: ModelsHCPCSBase) => {
				return code.code + ": " + code?.title;
			}}
			isOptionEqualToValue={(option: ModelsHCPCSBase, value: ModelsHCPCSBase) => {
				return option.code === value.code;
			}}
			options={dropdownOptions ?? []}
			onInputChange={(_event, value) => {
				setQuery(value);
			}}
			onChange={(_event, currentValue: ModelsHCPCSBase | null) => {
				props.setValue && props.setValue(currentValue?.code ?? null);
			}}
			renderInput={(params) => <TextField {...params} label="" placeholder={"CPT code / name"} />}
		/>
	);
}
export { ProcedureSelect };
