import { Box } from "@mui/material";
import ValueTile from "../../../../../shared/components/ValueTile";
import displayConverter from "../../../../../shared/utilities/displayConverter";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";

import { ModelsDoctorOverviewModel } from "api";
import { Loader } from "shared/components/Loaders/Loader";

SwiperCore.use([Pagination]);

function DoctorOverviewHeaderTiles({ overview_data }: { overview_data: ModelsDoctorOverviewModel | null }) {
	return (
		<Box
			sx={{
				"& .swiper-container": {
					pb: { xs: 4, xl: 0 },
				},
				"& .swiper-pagination-bullets": {
					bottom: "0",
				},
			}}
		>
			<Swiper
				spaceBetween={16}
				slidesPerView={1}
				onSlideChange={() => console.log("slide change")}
				onSwiper={(swiper) => console.log(swiper)}
				pagination={{
					clickable: true,
				}}
				breakpoints={{
					"700": {
						slidesPerView: 3,
					},
					"1200": {
						slidesPerView: 4,
					},
					"1500": {
						slidesPerView: 5,
					},
					"1900": {
						slidesPerView: 6,
					},
				}}
			>
				{overview_data ? (
					<>
						<SwiperSlide>
							<ValueTile value={displayConverter.insertThousandsCommas(overview_data.totalDoctors)} title="Total Doctors" minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.insertThousandsCommas(overview_data.totalAppointments)} title="Visits" minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.insertThousandsCommas(overview_data.totalPrescriptions)}
								title="Prescriptions"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.insertThousandsCommas(overview_data.totalProcedures)} title="Procedures" minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.insertThousandsCommas(overview_data.totalReferrals)} title="Referrals" minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.toUSD(Number(overview_data.totalBilling))} title="Billing" minHeight="7rem" />
						</SwiperSlide>
					</>
				) : (
					<Loader />
				)}
			</Swiper>
		</Box>
	);
}

export { DoctorOverviewHeaderTiles };
