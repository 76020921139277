import { ReactNode, useState } from "react";
import { Autocomplete, SxProps, TextField, Theme } from "@mui/material";
import { useX_getPatientDropdown } from "api_extension/useQueryX/cachePatientData/useX_getPatientDropdown";

type PatientSelectProps = {
	icon?: ReactNode;
	placeholder?: string;
	styles?: SxProps<Theme>;
	setValue?: (values: string | null) => void;
};
function PatientSelect(props: Readonly<PatientSelectProps>) {
	let [query, setQuery] = useState<string>("");
	let dropdownOptions = useX_getPatientDropdown({ searchQuery: query }).data?.patientIds;
	return (
		<Autocomplete
			sx={{
				minWidth: "30rem",
				width: "100%",
				...props.styles,
			}}
			autoComplete
			options={dropdownOptions ?? []}
			onInputChange={(_event, value) => {
				setQuery(value);
			}}
			onChange={(_event, currentValue: string | null) => {
				props.setValue && props.setValue(currentValue ?? null);
			}}
			renderInput={(params) => <TextField {...params} label="" placeholder={"Member Id"} />}
		/>
	);
}
export { PatientSelect };
