import { FilterQueryRequest, useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { DoctorGetDoctorsByLocationQuery, getAllDoctors } from "api";
import { useQuery } from "react-query";

export const useX_getAllDoctors = (fqr?: FilterQueryRequest | null) => {
	let fqrX = useFilterQuery();

	if (!fqr) {
		fqr = fqrX;
	}

	let request: DoctorGetDoctorsByLocationQuery = {
		memberTypes: fqr.memberTypes,
		startDate: fqr.startDate,
		endDate: fqr.endDate,
		page: 1,
		pageSize: 1,
		searchQuery: "",
		doctorCount: 0,
	};

	let cacheKeys = ["retrieveCacheDoctorData", "retrieveCacheDoctorData_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getAllDoctors.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};
