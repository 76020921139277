/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  CacheMonthlyDataGetCacheMonthlyAggregationResult,
  RequestModelsCacheMonthlyQueryRequestRiskModel,
  CacheMonthlyDataGetCacheMonthlyDetailResult,
  RequestModelsCacheMonthlyQueryRequest,
  CacheMonthlyDataGetCacheMonthlyDetailCombinedResult,
  CacheMonthlyDataGetSpendSummaryChartsResult,
  CacheMonthlyDataGetTotalMembersResult,
  CacheMonthlyDataGetTotalMembersQuery
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getCacheMonthlyAggregation = (
    requestModelsCacheMonthlyQueryRequestRiskModel: RequestModelsCacheMonthlyQueryRequestRiskModel,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<CacheMonthlyDataGetCacheMonthlyAggregationResult>(
      {url: `/api/admin/CacheMonthlyData/GetCacheMonthlyAggregation`, method: 'post',
      data: requestModelsCacheMonthlyQueryRequestRiskModel
    },
      options);
    }
  


    export const useGetCacheMonthlyAggregation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getCacheMonthlyAggregation>, TError,{data: RequestModelsCacheMonthlyQueryRequestRiskModel}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getCacheMonthlyAggregation>, {data: RequestModelsCacheMonthlyQueryRequestRiskModel}> = (props) => {
          const {data} = props || {};

          return  getCacheMonthlyAggregation(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getCacheMonthlyAggregation>, TError, {data: RequestModelsCacheMonthlyQueryRequestRiskModel}, TContext>(mutationFn, mutationOptions)
    }
    export const getCacheMonthlyDetail = (
    requestModelsCacheMonthlyQueryRequest: RequestModelsCacheMonthlyQueryRequest,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<CacheMonthlyDataGetCacheMonthlyDetailResult>(
      {url: `/api/admin/CacheMonthlyData/GetCacheMonthlyDetail`, method: 'post',
      data: requestModelsCacheMonthlyQueryRequest
    },
      options);
    }
  


    export const useGetCacheMonthlyDetail = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getCacheMonthlyDetail>, TError,{data: RequestModelsCacheMonthlyQueryRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getCacheMonthlyDetail>, {data: RequestModelsCacheMonthlyQueryRequest}> = (props) => {
          const {data} = props || {};

          return  getCacheMonthlyDetail(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getCacheMonthlyDetail>, TError, {data: RequestModelsCacheMonthlyQueryRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getCacheMonthlyDetailCombined = (
    requestModelsCacheMonthlyQueryRequest: RequestModelsCacheMonthlyQueryRequest,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<CacheMonthlyDataGetCacheMonthlyDetailCombinedResult>(
      {url: `/api/admin/CacheMonthlyData/GetCacheMonthlyDetailCombined`, method: 'post',
      data: requestModelsCacheMonthlyQueryRequest
    },
      options);
    }
  


    export const useGetCacheMonthlyDetailCombined = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getCacheMonthlyDetailCombined>, TError,{data: RequestModelsCacheMonthlyQueryRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getCacheMonthlyDetailCombined>, {data: RequestModelsCacheMonthlyQueryRequest}> = (props) => {
          const {data} = props || {};

          return  getCacheMonthlyDetailCombined(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getCacheMonthlyDetailCombined>, TError, {data: RequestModelsCacheMonthlyQueryRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getSpendSummaryCharts = (
    requestModelsCacheMonthlyQueryRequest: RequestModelsCacheMonthlyQueryRequest,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<CacheMonthlyDataGetSpendSummaryChartsResult>(
      {url: `/api/admin/CacheMonthlyData/GetSpendSummaryCharts`, method: 'post',
      data: requestModelsCacheMonthlyQueryRequest
    },
      options);
    }
  


    export const useGetSpendSummaryCharts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getSpendSummaryCharts>, TError,{data: RequestModelsCacheMonthlyQueryRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getSpendSummaryCharts>, {data: RequestModelsCacheMonthlyQueryRequest}> = (props) => {
          const {data} = props || {};

          return  getSpendSummaryCharts(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getSpendSummaryCharts>, TError, {data: RequestModelsCacheMonthlyQueryRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getTotalMembers = (
    cacheMonthlyDataGetTotalMembersQuery: CacheMonthlyDataGetTotalMembersQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<CacheMonthlyDataGetTotalMembersResult>(
      {url: `/api/admin/CacheMonthlyData/GetTotalMembers`, method: 'post',
      data: cacheMonthlyDataGetTotalMembersQuery
    },
      options);
    }
  


    export const useGetTotalMembers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getTotalMembers>, TError,{data: CacheMonthlyDataGetTotalMembersQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getTotalMembers>, {data: CacheMonthlyDataGetTotalMembersQuery}> = (props) => {
          const {data} = props || {};

          return  getTotalMembers(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getTotalMembers>, TError, {data: CacheMonthlyDataGetTotalMembersQuery}, TContext>(mutationFn, mutationOptions)
    }
    