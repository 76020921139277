import { ReactNode, useState } from "react";
import { Autocomplete, SxProps, TextField, Theme } from "@mui/material";
import { useX_getDoctorsBySearchTerm } from "api_extension/useQueryX/doctor/useX_getDoctorsBySearchTerm";
import { ModelsDoctorStub } from "api";

type DoctorSelectProps = {
	icon?: ReactNode;
	placeholder?: string;
	styles?: SxProps<Theme>;
	setValue?: (values: string | null) => void;
};
function DoctorSelect(props: Readonly<DoctorSelectProps>) {
	let [query, setQuery] = useState<string>("");
	let dropdownOptions = useX_getDoctorsBySearchTerm({ searchQuery: query, size: 20 }).data?.doctors;
	return (
		<Autocomplete
			sx={{
				minWidth: "30rem",
				width: "100%",
				...props.styles,
			}}
			autoComplete
			options={dropdownOptions ?? []}
			getOptionLabel={(doc: ModelsDoctorStub) => {
				return doc.firstName + " " + doc.lastName + ": " + doc.npi;
			}}
			isOptionEqualToValue={(option: ModelsDoctorStub, value: ModelsDoctorStub) => {
				return option.npi === value.npi;
			}}
			onInputChange={(_event, value) => {
				setQuery(value);
			}}
			onChange={(_event, currentValue: ModelsDoctorStub | null) => {
				props.setValue && props.setValue(currentValue?.npi ?? null);
			}}
			renderInput={(params) => <TextField {...params} label="" placeholder={"Npi Number"} />}
		/>
	);
}
export { DoctorSelect };
