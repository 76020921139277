import { Box } from "@mui/material";

import { DoctorOverviewHeaderTiles } from "./DoctorOverviewHeaderTiles";
import { useX_getAllDoctors } from "api_extension/useQueryX/doctor/useX_GetAllDoctors";
import { CacheMonthlyListDisplay } from "sections/followed_items/CacheMonthlyListDisplay";
import { DataGridColumns } from "shared/utilities/DataGridColumns/DataGridColumns";
import { DoctorSelect } from "sections/item_details/doctor/DoctorSelect";
import { useState } from "react";

const columns_cmd = DataGridColumns.CacheMonthly("PROVIDER_LIST");

function DoctorOverviewList() {
	const { data } = useX_getAllDoctors();
	let [selected, setSelected] = useState<string | null>(null);
	return (
		<Box>
			<DoctorOverviewHeaderTiles overview_data={data?.model ?? null} />
			<CacheMonthlyListDisplay
				itemType={"PROVIDER"}
				titleText="Doctor List"
				columns={columns_cmd}
				useSortBarSelect={true}
				searchBar={<DoctorSelect styles={{ width: 30 }} setValue={setSelected} />}
				query={selected}
			/>
		</Box>
	);
}

export { DoctorOverviewList };
