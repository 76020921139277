import { ReactNode, useState } from "react";
import { Autocomplete, SxProps, TextField, Theme } from "@mui/material";
import { ModelsTerminalDrugStructureDataStub, useGetMedicationDropdownList } from "api";
import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";

type MedicationSelectProps = {
	icon?: ReactNode;
	placeholder?: string;
	styles?: SxProps<Theme>;
	setValue?: (values: string | null) => void;
};
function MedicationSelect(props: Readonly<MedicationSelectProps>) {
	let [query, setQuery] = useState<string>("");
	let jobIds = useFilterQuery().memberTypes.toString();
	let dropdownOptions = useGetMedicationDropdownList({ searchQuery: query, jobIds }).data?.medications;
	return (
		<Autocomplete
			sx={{
				minWidth: "30rem",
				width: "100%",
				...props.styles,
			}}
			autoComplete
			getOptionLabel={(code: ModelsTerminalDrugStructureDataStub) => {
				return code.ndc + ": " + code?.name;
			}}
			isOptionEqualToValue={(option: ModelsTerminalDrugStructureDataStub, value: ModelsTerminalDrugStructureDataStub) => {
				return option.ndc === value.ndc;
			}}
			options={dropdownOptions ?? []}
			onInputChange={(_event, value) => {
				setQuery(value);
			}}
			onChange={(_event, currentValue: ModelsTerminalDrugStructureDataStub | null) => {
				props.setValue && props.setValue(currentValue?.ndc ?? null);
			}}
			renderInput={(params) => <TextField {...params} label="" placeholder={"NDC code / name"} />}
		/>
	);
}
export { MedicationSelect };
