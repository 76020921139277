import { Box, SxProps } from "@mui/material";
import { useMemo } from "react";
type IconSVG = { svg: JSX.Element; viewBox?: string };

type HealthIconProps = {
	size?: number;
	iconSVG: IconSVG;
	color?: string;
	hoverColor?: string;
};
export type { IconSVG, HealthIconProps };

export const HealthIcon = (props: HealthIconProps) => {
	let size = props.size ?? 24;
	const sx = useMemo(() => {
		let sx: SxProps = {
			"& svg path": {
				fill: "currentcolor",
				stroke: "currentcolor",
				strokeWidth: 0.2,
			},
		};

		if (props.color && props.hoverColor) {
			sx["&:not(:hover)"] = {
				color: props.color,
			};

			sx["&:hover"] = {
				color: props.hoverColor,
			};
		} else if (props.color) {
			sx.color = props.color;
		}
		return sx;
	}, [props]);
	return (
		<Box
			className="HealthIcon"
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				marginBottom: 0,
				paddingBottom: 0,
				margin: 0,
				padding: 0,
				height: size,
				color: props.hoverColor ? undefined : props.color,
			}}
			sx={sx}
		>
			<svg
				width={`${size}`}
				height={`${size}`}
				viewBox={props.iconSVG.viewBox ?? "0 0 48 48"}
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				style={{ margin: 0 }}
			>
				{props.iconSVG.svg}
			</svg>
		</Box>
	);
};

type HealthSVGNames = "rx" | "home_alt" | "doctor";

type HealthIconsSVGTypes = {
	[key in HealthSVGNames]: IconSVG;
};

// mostly from https://healthicons.org/ // some from fontAwesome
export const health_icons_svg: HealthIconsSVGTypes = {
	rx: {
		svg: (
			<path
				xmlns="http://www.w3.org/2000/svg"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27 15V4H10C9.44772 4 9 4.44772 9 5V43C9 43.5523 9.44772 44 10 44H38C38.5523 44 39 43.5523 39 43V16H28C27.4477 16 27 15.5523 27 15ZM29 14V4.58579L38.4142 14H29ZM17 19C17 18.4477 17.4477 18 18 18H23C25.2091 18 27 19.7909 27 22C27 24.0759 25.4186 25.7825 23.3948 25.9808L27 29.5859L30.2929 26.293L31.7072 27.7072L28.4142 31.0001L31.7071 34.293L30.2928 35.7072L27 32.4143L23.7072 35.7072L22.2929 34.293L25.5858 31.0001L20.5857 26H19V33H17V25V19ZM23 24H19V20H23C24.1046 20 25 20.8954 25 22C25 23.1046 24.1046 24 23 24Z"
				fill="currentColor"
			/>
		),
	},
	home_alt: {
		svg: (
			<path
				d="M41.7083 25.7942L24.0579 8.08594L6.29405 25.7919C5.90289 26.1818 5.90185 26.8149 6.29174 27.2061C6.68163 27.5973 7.31479 27.5983 7.70595 27.2084L11 23.9251V39C11 39.5523 11.4477 40 12 40H19C19.5523 40 20 39.5523 20 39V31.0002C20 29.8957 20.8954 29.0002 22 29.0002H26C27.1046 29.0002 28 29.8957 28 31.0002V39C28 39.5523 28.4477 40 29 40H36C36.5523 40 37 39.5523 37 39V23.9036L40.2917 27.2061C40.6816 27.5973 41.3148 27.5983 41.7059 27.2084C42.0971 26.8185 42.0981 26.1854 41.7083 25.7942Z"
				fill="black"
			/>
		),
	},
	doctor: {
		svg: (
			<>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M14.4326 33.4416C13.5732 33.9692 13 34.9177 13 36C13 37.6569 14.3431 39 16 39C17.6569 39 19 37.6569 19 36C19 34.476 17.8636 33.2174 16.3919 33.0254C16.3591 32.9079 16.3249 32.7725 16.2901 32.6206C16.1703 32.0972 16.056 31.4332 15.9709 30.7512C15.8856 30.0679 15.8327 29.3904 15.8292 28.8375C15.8279 28.6348 15.8334 28.4582 15.8446 28.3101C16.2293 28.1997 16.6151 28.1003 17 28.0126C17.4413 27.9121 17.7025 28.433 17.9141 28.8551C17.9436 28.9139 17.9721 28.9707 18 29.0237H22.0639C22.9797 29.0235 23.4324 29.0236 23.8903 29.0237C24.3376 29.0238 24.7906 29.0239 25.6851 29.0237H29.7493C29.9775 28.5902 30.4972 27.8981 31 28.0126C31.5363 28.1348 32.0745 28.2798 32.6089 28.4456L32.6056 28.4472C32.6044 28.4449 32.6045 28.4453 32.6057 28.4487C32.6104 28.4627 32.6324 28.5281 32.6542 28.6699C32.6791 28.8313 32.6964 29.0411 32.7035 29.2937C32.7177 29.798 32.6894 30.411 32.6297 31.0287C32.5699 31.6456 32.4811 32.2429 32.3811 32.7144C32.359 32.8188 32.3369 32.9141 32.3152 33H31C30.6212 33 30.275 33.214 30.1056 33.5528L29.1056 35.5528C29.0361 35.6916 29 35.8448 29 36V38C29 38.5523 29.4477 39 30 39H32V37H31V36.2361L31.618 35H34.382L35 36.2361V37H34V39H36C36.5523 39 37 38.5523 37 38V36C37 35.8448 36.9639 35.6916 36.8944 35.5528L35.8944 33.5528C35.725 33.214 35.3788 33 35 33H34.3643C34.4711 32.4673 34.56 31.8448 34.6203 31.2213C34.6856 30.5473 34.7198 29.8478 34.7027 29.2376L34.7019 29.2101C38.6895 30.8949 42 33.6661 42 36.5699V42H6V36.5699C6 33.5376 9.60993 30.6499 13.831 28.993C13.8424 29.6146 13.9013 30.3182 13.9862 30.9988C14.0779 31.7335 14.2029 32.4653 14.3406 33.0669C14.3701 33.1958 14.4008 33.3213 14.4326 33.4416ZM16 37.0147C16.5384 37.0147 17 36.5742 17 36C17 35.4258 16.5384 34.9853 16 34.9853C15.4616 34.9853 15 35.4258 15 36C15 36.5742 15.4616 37.0147 16 37.0147Z"
					fill="currentColor"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M24 24C28.4183 24 32 20.4183 32 16C32 11.5817 28.4183 8 24 8C19.5817 8 16 11.5817 16 16C16 20.4183 19.5817 24 24 24ZM24 26C29.5228 26 34 21.5228 34 16C34 10.4772 29.5228 6 24 6C18.4772 6 14 10.4772 14 16C14 21.5228 18.4772 26 24 26Z"
					fill="currentColor"
				/>
			</>
		),
	},
};

const HealthIconDemoList = () => {
	let icons = Object.keys(health_icons_svg).map((propName) => {
		return (
			<Box key={propName} sx={{ display: "flex", borderBottom: "1px dotted #999999", justifyContent: "right" }}>
				<Box style={{ width: "50px" }}>
					<Box sx={{ width: "50%" }}>
						<HealthIcon iconSVG={health_icons_svg[propName as HealthSVGNames]} />
					</Box>
				</Box>
				<Box sx={{ width: "50%" }}>{propName}</Box>
			</Box>
		);
	});

	return <Box>{icons}</Box>;
};

export { HealthIconDemoList };
